import Navbar from "./component/navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./component/home";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar></Navbar>
        <Routes>
          <Route path="/Home" element={<Home />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
