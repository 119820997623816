import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <div className="w-full h-auto flex md:h-auto md:w-full md:flex md:mx-6 justify-between bg-slate-600">
      <div class="w-16 h-full p-2 ">
        <img
          class="h-full w-full rounded"
          src="https://i.pinimg.com/564x/98/fe/78/98fe785fd4c125dcfb4593f0c6dc8b2f.jpg"
        ></img>
      </div>
      <div className="flex justify-center text-center">
        <ul className=" h-full w-auto flex justify-center items-center ">
          <li>
            <Link
              className="text-white mx-3 hover:text-yellow-400 p-1 font-bold italic"
              to="/Home"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              className="text-white mx-3 hover:text-yellow-400 p-1 font-bold italic"
              to="/About-us"
            >
              About-us
            </Link>
          </li>
          <li>
            <Link
              className="text-white mx-3 hover:text-yellow-400 p-1 font-bold italic"
              to="Contact-us"
            >
              Contact-us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Navbar;
