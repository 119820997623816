import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const Home = () => {
  const data = [
    "https://c8.alamy.com/comp/HP0RCF/amazon-logo-HP0RCF.jpg",
    "https://thumbs.dreamstime.com/z/amazon-logo-black-shiny-wall-california-best-buy-store-fox-hills-culver-city-october-american-international-electronic-135531802.jpg",
    "https://th.bing.com/th/id/OIP.AuggztWv8Hwzt2zjbJcztAHaEK?w=321&h=180&c=7&r=0&o=5&dpr=1.1&pid=1.7",
  ];
  return (
    <div className="mainbox">
      <div className="outerbox">
        <div className="innerbox">
          <Carousel
            className="carousel"
            autoPlay={true} // Enables auto-play
            infiniteLoop={true} // Carousel loops infinitely
            showThumbs={false} // Hide thumbnail indicators
            showStatus={false} // Hide the status indicator (slide count)
            showArrows={true} // Show arrows for navigation
            interval={3000} // Set auto-play interval (in milliseconds)
            transitionTime={500} // Set transition time for each slide
          >
            {data.map((imag, i) => {
              return <img key={i} src={imag} alt="" />;
            })}
          </Carousel>
        </div>
      </div>
      {/* contact form page */}
      <div className=" h-60 bg-white">
        <div className="h-full w-full flex flex-col">
          <h1
            class="text-xl font-bold italic
          "
          >
            Consult to an interior designer
          </h1>
          <div className="border-2 border-black ">
            <input
              class="border-2 border-black my-2 h-auto w-64 ml-16 rounded-md text-slate-900 placeholder-slate-950 italic"
              type="text"
              placeholder="Name"
            ></input>

            <input
              class="border-2 border-black my-2 h-auto w-64 ml-16 rounded-md text-slate-900 placeholder-slate-950 italic"
              type="text"
              placeholder="Phone"
            ></input>

            <input
              class="border-2 border-black my-2 h-auto w-64 ml-16 rounded-md text-slate-900 placeholder-slate-950 italic"
              type="text"
              placeholder="Email"
            ></input>

            <input
              class="border-2 border-black my-2 h-auto w-64 ml-16 rounded-md text-slate-900 placeholder-slate-950 italic"
              type="text"
              placeholder="Address"
            ></input>

            <button className="h-auto w-36  mx-32 text-center border-2 bg-slate-800 text-gray-100 rounded-2xl hover:bg-white hover:text-black">
              Submit
            </button>
          </div>
        </div>
      </div>
      {/*product section*/}
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col justify-center items-center">
          <img
            class="rounded-tl-[50px] rounded-br-[50px]  h-96 w-96"
            src="https://i.pinimg.com/564x/21/16/44/2116448000606243390ffbe5aebe6439.jpg"
          ></img>
          <button className="border-2 rounded-md bg-slate-800 text-white italic w-20 mb-7">
            {" "}
            Wardrobe
          </button>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img
            class="rounded-tl-[50px] rounded-br-[50px]  h-96 w-96"
            src="https://i.pinimg.com/564x/29/ab/4f/29ab4ff68572044ca68f65e20dca1afb.jpg"
          ></img>
          <button className="border-2 rounded-md bg-slate-800 text-white italic w-20 mb-7">
            Kitchen
          </button>
        </div>
        <div className="flex flex-col justify-center items-center ">
          <img
            class="rounded-tl-[50px] rounded-br-[50px]  h-96 w-96"
            src="https://i.pinimg.com/564x/43/30/6f/43306ff3115ae85abefa95434b1ccf5e.jpg"
          ></img>
          <button className="border-2 rounded-md bg-slate-800 text-white italic w-20 mb-7">
            Tv Pannel
          </button>
        </div>
      </div>

      {/*motivational lines*/}
      <div className="outerbox">
        <div className="motivational">
          <div>
            <i class="fa-solid fa-certificate logo"></i>
          </div>
          <div className="child_m">
            <h3> Time To Meet Your</h3>
            <h1> New Home</h1>
          </div>
        </div>
        <div className="m_image">
          <img src="https://i.pinimg.com/564x/3e/eb/3d/3eeb3d8c35b8f5370cd4c201b25e4447.jpg"></img>
        </div>
      </div>

      {/*what We Offer*/}
      <div className="outerbox">
        <div className="offersection">
          <h1>What we Offer</h1>
        </div>
        <div className="comments">
          <div className="commentbox">
            <i class="fa-solid fa-certificate logo"></i>
            <div className="writing">
              <h1>EXPERIENCED TEAM</h1>
              <h3>
                Each of us is a master of our craft – we've worked a number of
                years in our field and each have passion and creative vision for
                every project we put our hands on.
              </h3>
            </div>
          </div>
          <div className="commentbox">
            <i class="fa-solid fa-certificate logo"></i>
            <div className="writing">
              <h1>QUALITY TO DIE FOR</h1>
              <h3>
                We pay a lot of attention to each and every detail, because we
                know that we are in charge of creating somebody's safe haven.
              </h3>
            </div>
          </div>
          <div className="commentbox">
            <i class="fa-solid fa-certificate logo"></i>
            <div className="writing">
              <h1>VISIONARIES OF THE FUTURE </h1>
              <h3>
                We're not scared to go beyond the horizon. As the visionaries of
                tomorrow, we won't say no to experimentation, unusual materials,
                or unlikely combinations.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
